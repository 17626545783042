import { Navigate, useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useUser } from '../config/connection/auth';

export const Routes = () => {
  const auth = useUser();

  const commonRoutes = [{ path: '*', element: auth.data ? <Navigate to="/app/dashboard" /> : <Navigate to="/auth/login" />, replace: true }];

  // o typeof(auth.data) !== "string" é porque quando auth.data for uma string significa que o back retornou um erro, e auth.data é a mensagem desse erro,
  // que deve ser mandada como notificação na tela
  const routes = auth.data && typeof(auth.data) !== "string" ? protectedRoutes : publicRoutes;
  
  // const routes = auth.data ? protectedRoutes : publicRoutes; // tenho que lembrar por que eu fiz isso kkkkkkkkkkkkkkk

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};