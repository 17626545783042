import { FormEvent, useState } from 'react';
import { useRegister } from '../../../config/connection/auth';
import RegisterForm from '../components/RegisterForm';
import { WarnTypes, arePasswordsEqual, validateEmail, validateName, validatePassword } from '../../../utils/helpers';
import { useNotificationStore } from '../../../stores/notifications';
import { useNavigate } from 'react-router-dom';

interface IWarn {
  message: string;
  id: WarnTypes;
}

export const Register = () => {
  const register = useRegister();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  const [warns, setWarns] = useState<IWarn[]>([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    
    const target = event.target as typeof event.target & {
      email: { value: string };
      password: { value: string };
      name: { value: string };
      confirmPassword: { value: string };
    };

    const name = target.name.value;
    const email = target.email.value;
    const password = target.password.value;
    const confirmPassword = target.confirmPassword.value;

    const arePasswordsTheSame = arePasswordsEqual(password, confirmPassword);
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    const isNameValid = validateName(name);

    const validationArray = [isEmailValid, isPasswordValid, isNameValid, arePasswordsTheSame];
    const validator = validationArray.filter(e => e.status === false);

    setWarns(validator);
    if(validator.length !== 0) return;

    setLoading(true);
    const data = await register.mutateAsync({ email, password, name });
    setLoading(false);

    if(typeof(data) === "string") {
      addNotification({ type: "warning", title: data });
    } else {
      addNotification({ type: "info", title: "Email de verificação enviado!" });
      navigate("/auth/login");
    }
  }

  return <RegisterForm onSubmit={onSubmit} warns={warns} loading={loading}/>;
};