// AUTH / API CONSTANTS
export const API_URL = process.env.REACT_APP_API_URL as string || "https://api.rico.net.br/v1/";
export const REGISTER_ROUTE = '/user';
export const LOGIN_ROUTE = '/auth/login';
export const DASHBOARD_ROUTE = '/app/dashboard';
export const REPORT_ROUTE = '/app/report';
export const RECOVER_ROUTE = '/auth/password-recovery';
export const RESET_PASSWORD_ROUTE = '/auth/password-recovery/'

// LAYOUT CONSTANTS
export const SIDEBAR_ICON_SIZE: number = 23; // random units