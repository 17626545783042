import { FormEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillShieldLockFill } from 'react-icons/bs';
import Input from '../../../components/Elements/Input';
import WarnList from '../../../components/Elements/WarnList';

interface IForm {
    onSubmit: FormEventHandler<HTMLFormElement>;
    warns: any;
    loading: boolean;
}

export default function RegisterForm({ onSubmit, warns, loading }: IForm) {
    const navigate = useNavigate();

    return (
        <div className='auth-container' style={{ cursor: loading ? "wait" : "pointer", opacity: loading ? 0.7 : 1 }}>
            <div className='auth-padlock'> <BsFillShieldLockFill /> </div>
            <span>Cadastro</span>
            <form autoComplete='off' onSubmit={onSubmit}>
                <WarnList warns={warns}/>
                <Input id='name' label='Nome *' />
                <Input id='email' label='Endereço de Email *' />
                <Input id='password' label='Senha *' type='password' />
                <Input id='confirmPassword' label='Confirmar Senha *' type='password' />
                <label>
                    <input type='checkbox' id='rememberMe' autoComplete='one-time-code'/>
                    <span>Lembrar de mim</span>
                </label>
                <button type='submit' disabled={loading} className='auth-send'>CONFIRMAR</button>
                <div className='auth-footer'>
                    <span onClick={() => navigate("/auth/password-recovery")}>Esqueceu a senha?</span>
                    <span onClick={() => navigate('/auth/login')}>Já tem conta? Entrar.</span>
                </div>
            </form>
        </div>
    )
}