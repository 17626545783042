import React from 'react';
import ReactDOM from 'react-dom/client';
import { Metric } from 'web-vitals';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/main.css';

const root = ReactDOM.createRoot( document.getElementById('root') as HTMLElement );

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric: Metric) => console.log("Report Web Vitals:", metric));
