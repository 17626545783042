import { useContext } from 'react';
import { SidebarData } from "./SidebarData";
import { CgDetailsMore } from 'react-icons/cg';
import { SidebarContext } from '../../../providers/SidebarProvider';
import { useUser } from '../../../config/connection/auth';
import { AuthUser } from '../../../features/auth';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
    const { isOpen, setOpen, item, setItem } = useContext(SidebarContext);
    const navigate = useNavigate();

    const auth = useUser();

    const expandedClass: string = isOpen ? '' : '--collapse';
    const name: string = (auth.data as AuthUser).name || "ADMIN";

    return (
        <aside className={'sidebar'+expandedClass}>
            <div className={"logo-sidebar"+expandedClass}>
                <div>
                    <div><CgDetailsMore size={35} color='#e1e4e8' onClick={() => setOpen(!isOpen)}/></div>
                    {isOpen && <span>{name.slice(0, 35)}</span>}
                </div>
            </div>
            <ul>
                {SidebarData.map((val, key) => (
                    <>
                        <li
                            key={key} 
                            onClick={() => {
                                setItem(key);
                                navigate(`/app/${val.link}`);
                            }}
                        >
                            <div>{val.icon}</div>
                            {isOpen && <span>{val.title}</span>}
                            {item === key && <div className='item-selected'></div>}
                        </li>
                        {key === 2 && <hr className='sidebar-division'/>}
                    </>
                ))}
            </ul>
        </aside>
    )
}

export default Sidebar;