import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '../components/Elements';
import { Layout } from '../components/Layout';
import { lazyImport } from '../utils/lazyImport';

const { Dashboard } = lazyImport(() => import('../features/dashboard'), 'Dashboard');
const { Report } = lazyImport(() => import('../features/report'), 'Report');
const { Profile } = lazyImport(() => import('../features/user'), 'Profile');
const { Statistics } = lazyImport(() => import('../features/statistics'), 'Statistics');
const { Faq } = lazyImport(() => import('../features/faq'), 'Faq');
const { Support } = lazyImport(() => import('../features/support'), 'Support');

const { CoomingSoon } = lazyImport(() => import('../features/misc'), 'CoomingSoon');

export const Spin = () => (
  <div className="spinner-container">
    <Spinner />
  </div>
)

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<Spin />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};

export const protectedRoutes = [
  {
    path: '/app/*',
    element: <App />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'profile', element: <Profile /> },
      { path: 'stats', element: <CoomingSoon /> }, // <Statistics />
      { path: 'faq', element: <Faq /> },
      { path: 'support', element: <Support /> },
      { path: 'report', element: <Report /> },
    ],
  },
];