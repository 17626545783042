import { REGISTER_ROUTE } from '../../../config';
import { axios } from '../../../config/connection/axios';

import { UserResponse } from '../types';

export type RegisterCredentialsDTO = {
  email: string;
  password: string;
  name: string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentialsDTO
): Promise<UserResponse> => {
  return axios.post(REGISTER_ROUTE, data);
};