import { AiOutlineUser } from 'react-icons/ai';
import { RxDashboard } from 'react-icons/rx';
import { BiMath, BiSupport } from 'react-icons/bi';
import { FaQuestionCircle } from 'react-icons/fa';
import { SIDEBAR_ICON_SIZE } from '../../../config';

export const SidebarData = [
    {
        title: "Dashboard",
        icon: <RxDashboard size={SIDEBAR_ICON_SIZE}/>,
        link: "dashboard"
    },
    {
        title: "Perfil",
        icon: <AiOutlineUser size={SIDEBAR_ICON_SIZE}/>,
        link: "profile"
    },
    {
        title: "Estatísticas",
        icon: <BiMath size={SIDEBAR_ICON_SIZE}/>,
        link: "stats"
    },
    {
        title: "FAQ",
        icon: <FaQuestionCircle size={SIDEBAR_ICON_SIZE}/>,
        link: "faq"
    },
    {
        title: "Suporte",
        icon: <BiSupport size={SIDEBAR_ICON_SIZE}/>,
        link: "support"
    }
]