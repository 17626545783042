import { FormEvent, useState } from 'react';
import { WarnTypes, arePasswordsEqual, validateEmail, validatePassword } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordFill } from 'react-icons/ri';
import WarnList from '../../../components/Elements/WarnList';
import Input from '../../../components/Elements/Input';
import { resetPassword, sendRecoveryEmail } from '../api/recoverPassword';
import { useNotificationStore } from '../../../stores/notifications';

interface IWarn {
    message: string;
    id: WarnTypes;
}

export function PasswordRecovery() {
    const navigate = useNavigate();
    const { addNotification } = useNotificationStore();
    const [warns, setWarns] = useState<IWarn[]>([]);
    
    const [sent, setSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");

    const onSubmitRecoveryCode = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const isEmailValid = validateEmail(email);

        setWarns([isEmailValid].filter(e => e.status === false));
        if(!isEmailValid.status) return;

        setLoading(true);
        await sendRecoveryEmail({ email });

        setLoading(false);
        setSent(true);
    }

    const onSubmitNewPassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            recoverToken: { value: string };
            password: { value: string };
            confirmPassword: { value: string };
        };

        const recoverToken = target.recoverToken.value;
        const password = target.password.value;
        const confirmPassword = target.confirmPassword.value;
     
        const isPasswordValid = validatePassword(password);
        const arePasswordsTheSame = arePasswordsEqual(password, confirmPassword);

        const validationArray = [isPasswordValid, arePasswordsTheSame];
        const validator = validationArray.filter(e => e.status === false);
    
        setWarns(validator);
        if(validator.length !== 0) return;

        setLoading(true);
        
        try {
            await resetPassword({ recoverToken, password, email });
            navigate("/auth/login");
            addNotification({ type: "info", title: "Senha alterada com sucesso" });
        } catch(error: any) {
            addNotification({ type: "error", title: error.response.data.message as string });
        }

        setLoading(false);
    }

    return (
        <div className='auth-container'>
            <div className='auth-padlock'> <RiLockPasswordFill /> </div>
            <span>{sent ? "Agora digite o código enviado no Email" : "Recuperação de Senha"}</span>
            <form autoComplete='off' onSubmit={sent ? onSubmitNewPassword : onSubmitRecoveryCode}>
                <WarnList warns={warns}/>
                {!sent && <Input  id='email'  label='Envie email de recuperação *' onChange={(event: any) => setEmail(event.target.value)} value={email} />}
                {sent && (
                    <>
                        <Input id='recoverToken' label='Código de recuperação *' onChange={(event: any) => setCode(event.target.value)} value={code} />
                        <Input id='password' label='Nova senha *' type='password' />
                        <Input id='confirmPassword' label='Confirmar senha *' type='password' />
                    </>
                )}
                <button className='auth-send' type='submit' disabled={loading} style={{ marginTop: "5%", cursor: loading ? "wait" : "pointer", opacity: loading ? 0.7 : 1 }}>ENVIAR</button>
                <div className='auth-footer'>
                    <span onClick={() => navigate("/auth/login")}>Login</span>
                    <span onClick={() => navigate('/auth/register')}>Cadastro</span>
                </div>
            </form>
        </div>
    )
}