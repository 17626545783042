import { RiErrorWarningFill } from 'react-icons/ri';
import { FaInfo } from 'react-icons/fa';
import { ImCheckmark } from 'react-icons/im';
import { TbFaceIdError } from 'react-icons/tb';

export type NotificationProps = {
    notification: {
      id: string;
      type: 'info' | 'warning' | 'success' | 'error';
      title: string;
    };
    onDismiss: (id: string) => void;
};

export default function Notification({ 
    notification: { id, type, title },
    onDismiss,
}: NotificationProps) {

    const Hashmap = {
        'warning': '#d9d145',
        'info': '#50b7d4',
        'success': '#50d466',
        'error': '#d4505d',
    }

    return (
        <div onClick={() => onDismiss(id)} style={{ borderLeft: `5px solid ${Hashmap[type]}` }}>
            {type === 'warning' && <RiErrorWarningFill color={Hashmap[type]} size={25} className='notification-icon'/>}
            {type === 'info' && <FaInfo color={Hashmap[type]} size={25} className='notification-icon'/>}
            {type === 'success' && <ImCheckmark color={Hashmap[type]} size={25} className='notification-icon'/>}
            {type === 'error' && <TbFaceIdError color={Hashmap[type]} size={25} className='notification-icon'/>}
            <span>{title}</span>
        </div>
    )
}