import React, { createContext, useEffect, useState } from "react";
import { ChildrenDto } from "../types";
import { SidebarData } from "../components/Layout/components/SidebarData";
import { useLocation } from "react-router-dom";

interface ISidebar {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: number;
  setItem: React.Dispatch<React.SetStateAction<number>>;
};

const DEFAULT_VALUE = {
  isOpen: true,
  setOpen: () => {},
  item: 0,
  setItem: () => {}
};

export const SidebarContext = createContext<ISidebar>(DEFAULT_VALUE);

export const SidebarProvider = ({ children }: ChildrenDto) => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(true);
  const [item, setItem] = useState(0);
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if( current !== location.pathname ) setCurrent(location.pathname);
    }

    const currentUrl = window.location.href.split('/').pop();
    const currentItem = SidebarData.findIndex(x => x.link === currentUrl);
    setItem(currentItem);
  }, [location, current]);

  return (
    <SidebarContext.Provider value={{ isOpen, setOpen, item, setItem }}>
      {children}
    </SidebarContext.Provider>
  );
};