import { FormEvent, useState } from "react";
import { useLogin } from "../../../config/connection/auth";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../../../config";
import { WarnTypes, validateEmail, validatePassword } from "../../../utils/helpers";
import { useNotificationStore } from "../../../stores/notifications";

interface IWarn {
  message: string;
  id: WarnTypes;
}

export const Login = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  const [warns, setWarns] = useState<IWarn[]>([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    
    const target = event.target as typeof event.target & {
      email: { value: string };
      password: { value: string };
    };

    const email = target.email.value;
    const password = target.password.value;

    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    const validationArray = [isEmailValid, isPasswordValid];
    const validator = validationArray.filter(e => e.status === false);

    setWarns(validator);
    if(validator.length !== 0) return;

    setLoading(true);
    const data = await login.mutateAsync({ email, password });
    setLoading(false);

    if(typeof(data) === "string") {
      addNotification({ type: "warning", title: data });
    } else if(data) {
      navigate(DASHBOARD_ROUTE);
    }
  }

  return <LoginForm onSubmit={onSubmit} warns={warns} loading={loading} />;
};