import { useNotificationStore } from "../../stores/notifications";
import Notification from "./Notification";

export default function Notifications() {
    const { notifications, dismissNotification } = useNotificationStore();

    return (
        <div className="notifications-wrapper">
            {notifications.map(notification => (
                <Notification
                    key={notification.id}
                    notification={notification}
                    onDismiss={dismissNotification}
                />
            ))}
        </div>
    )
}