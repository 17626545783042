import { configureAuth } from 'react-query-auth';

import {
  loginWithEmailAndPassword, // Function
  getUser, // Function
  registerWithEmailAndPassword, // Function
  UserResponse, // Type
  LoginCredentialsDTO, // Type
  RegisterCredentialsDTO, // Type
  AuthUser, // Type
} from '../../features/auth';
import storage from '../../utils/storage';

async function handleUserResponse(data: UserResponse) {
  const { token, user } = data;
  storage.setToken(token);
  return user;
}

async function userFn() {
  try {
    if (storage.getToken()) {
      const data = await getUser();
      return data;
    }
  
    return null;
  } catch(error) {
    console.error("Something went wrong", error);
    return null;
  }
}

async function loginFn(data: LoginCredentialsDTO) {
  try {
    const response = await loginWithEmailAndPassword(data);
    const user = await handleUserResponse(response);
    return user;
  } catch(error: any) {
    return error.response.data.message as string;
  }
}

async function registerFn(data: RegisterCredentialsDTO) {
  try {
    const response = await registerWithEmailAndPassword(data);
    const user = await handleUserResponse(response);
    return user;
  } catch(error: any) {
    return error.response.data.message as string;
  }
}

async function logoutFn() {
  storage.clearToken();
}

export const { useUser, useLogin, useRegister, useLogout } = configureAuth<AuthUser | string | null, unknown, LoginCredentialsDTO, RegisterCredentialsDTO>({
  userFn,
  loginFn,
  registerFn,
  logoutFn
});