import { useContext } from 'react';
import { FiSettings } from 'react-icons/fi';
import { BiExit } from 'react-icons/bi';
import { useLogout } from '../../../config/connection/auth';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../../config';
import { SidebarContext } from '../../../providers/SidebarProvider';

function Header() {
    const { isOpen } = useContext(SidebarContext);
    const navigate = useNavigate();
    const logout = useLogout();

    const exit = async () => {
        await logout.mutateAsync({});
        navigate(LOGIN_ROUTE);
    }

    const expandedClass: string = isOpen ? '' : '--collapse';

    return (
        <header className={"header"+expandedClass}>
            <div>
                <div>
                    <span className='header-title'>Realiza</span>
                </div>
                <div>
                    <div className='header-section' style={{ cursor: "not-allowed" }}> {/* Por enquanto vou deixar o not-allowed, pois não temos utilidade para esse botão no momento, mas no futuro é provável que iremos precisar */}
                        <div><FiSettings /></div>
                        <p>Configurações</p>
                    </div>
                    <div className='header-section' onClick={exit}>
                        <div><BiExit /></div>
                        <p>Sair</p>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;