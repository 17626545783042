const storagePrefix = 'ric0_mTghzWAcRX_';

const storage = {
  // Token Auth
  getToken: () => {
    const rawToken = window.localStorage.getItem(`${storagePrefix}token`);

    if(rawToken && rawToken !== "undefined") return JSON.parse(rawToken as string);
    
    return '';
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};

export default storage;