import { nanoid } from 'nanoid';
import { create } from 'zustand';

export type Notification = {
  id: string;
  type: 'info' | 'warning' | 'success' | 'error';
  title: string;
};

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
};

// Evil type
type Set = (partial: NotificationsStore | Partial<NotificationsStore> | ((state: NotificationsStore) => NotificationsStore | Partial<any>), replace?: boolean | undefined) => void

function createNotificationStore(set: Set): NotificationsStore {
  const dismissNotification = (id: string) => {
    return set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id),
    }))
  }

  const addNotification = (notification: Omit<Notification, 'id'>) => {
    return set((state) => {
      const id = nanoid();

      setTimeout(() => {
        return dismissNotification(id);
      }, 5000);

      return ({ notifications: [...state.notifications, { id: id, ...notification }] })
    });
  }

  return ({
    notifications: [],
    addNotification: addNotification,
    dismissNotification: dismissNotification,
  })
}


export const useNotificationStore = create<NotificationsStore>(createNotificationStore);