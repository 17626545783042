import { RECOVER_ROUTE } from '../../../config';
import { axios } from '../../../config/connection/axios';

export type RecoveryDto = {
  email: string;
};

export type ResetPassDto = {
    recoverToken: string;
    password: string;
    email: string;
}

export const sendRecoveryEmail = (data: RecoveryDto): Promise<any> => {
  return axios.post(RECOVER_ROUTE, data);
};

export const resetPassword = (data: ResetPassDto): Promise<any> => {
    return axios.post(RECOVER_ROUTE + `/${data.recoverToken}`, data);
}