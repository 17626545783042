import { LOGIN_ROUTE } from '../../../config';
import { axios } from '../../../config/connection/axios';

import { UserResponse } from '../types';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  return axios.post(LOGIN_ROUTE, data);
};