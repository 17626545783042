import { FormEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillShieldLockFill } from 'react-icons/bs';
import Input from '../../../components/Elements/Input';
import WarnList from '../../../components/Elements/WarnList';

interface IForm {
    onSubmit: FormEventHandler<HTMLFormElement>;
    warns: any;
    loading: boolean;
}

export default function LoginForm({ onSubmit, warns, loading }: IForm) {
    const navigate = useNavigate();

    return (
        <div className='auth-container' style={{ cursor: loading ? "wait" : "pointer", opacity: loading ? 0.7 : 1 }}>
            <div className='auth-padlock'> <BsFillShieldLockFill /> </div>
            <span>Login</span>
            <form autoComplete='off' onSubmit={onSubmit}>
                <WarnList warns={warns}/>
                <Input id='email' label='Endereço de Email *'/>
                <Input id='password' label='Senha *' type='password' />
                <label>
                    <input type='checkbox' id='rememberMe' autoComplete='one-time-code'/>
                    <span>Lembrar de mim</span>
                </label>
                <button type='submit' disabled={loading} className='auth-send'>ENVIAR</button>
                <div className='auth-footer'>
                    <span onClick={() => navigate("/auth/password-recovery")}>Esqueceu a senha?</span>
                    <span onClick={() => navigate('/auth/register')}>Cadastro</span>
                </div>
            </form>
        </div>
    )
}