import { BrowserRouter } from "react-router-dom";
import { ChildrenDto } from "../types";
import { SidebarProvider } from "./SidebarProvider";
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../config/connection/react-query';
import Notifications from "../components/Notifications/Notifications";

export const AppProvider = ({ children }: ChildrenDto) => {
    return (
        <QueryClientProvider client={queryClient}>
            <Notifications />
            <BrowserRouter>
                <SidebarProvider>
                    {children}
                </SidebarProvider>
            </BrowserRouter>
        </QueryClientProvider>
    );
};