type warn = {
    message: string;
    id: string;
}

interface IWarns {
    warns: warn[];
}

export default function WarnList({ warns }: IWarns) {
    return (
        <ul className='auth-warns'>
            {warns.map((e) => (
                <li>{e.message}</li>
            ))}
        </ul>
    )
}