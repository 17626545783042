import { useContext } from 'react';
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { SidebarContext } from '../../providers/SidebarProvider';
import { ChildrenDto } from "../../types";

export function Layout({ children }: ChildrenDto) {
    const { isOpen } = useContext(SidebarContext);

    const collapsedTailClass: string = isOpen ? '' : '--collapse';

    return (
        <main>
            <Sidebar />
            <section className={"content-view"+collapsedTailClass}>
                <Header />
                <div className="main-view">
                    {children}
                </div>
            </section>
        </main>
    )
}