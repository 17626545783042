interface IResponse {
    status: boolean;
    message: string;
    id: WarnTypes;
}

// export enum WarnTypes {
//     ZERO = 'ok',
//     ONE = 'Passwords_Must_Be_Equal',
//     TWO = 'Invalid_Email',
//     THREE = 'Email_Too_Long',
//     FOUR = 'Password_Too_Long',
//     FIVE = 'Password_Empty',
//     SIX = 'Name_Too_Long',
//     SEVEN = 'Invalid_Name'
// }

export enum WarnTypes {
    EMAIL = 'email',
    PASSWORD = 'password',
    NAME = 'name'
}

// The following is a complete RFC 5322 compliant regex, for further information see https://datatracker.ietf.org/doc/html/rfc5322 and https://emailregex.com/
// --- do not touch unless RFC updates.
// --- yes, it works, yes, for all emails
const strictEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

// This is a simpler regex for email, it's only supposed to be temporary. I want redundancy.
// It will lead to false negatives. Use wisely.
const permissiveEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/


// This won't allow for certain names with more exotic characters, the ".", "'" and "," are for names such as:
// Mathias d'Arras
// Martin Luther King, Jr.
// Hector Sausage-Hausen
const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;

export const validateEmail = (email: string): IResponse => {
    const isString = typeof(email) === "string";
    const isFormattedAsEmail = strictEmailRegex.test(email);
    const isFormattedAsEmailPermissive = permissiveEmailRegex.test(email);
    const isSmallerThanLimit = email.length < 64; // If you have an email with more than 64 characters you deserve this

    if(!isString) return { status: false, message: "Email deve ser um texto", id: WarnTypes.EMAIL };
    if(!isFormattedAsEmail) return { status: false, message: "Email inválido!", id: WarnTypes.EMAIL };
    if(!isFormattedAsEmailPermissive) return { status: false, message: "Email inválido!", id: WarnTypes.EMAIL };
    if(!isSmallerThanLimit) return { status: false, message: "Email muito longo!", id: WarnTypes.EMAIL };

    return { status: true, message: "", id: WarnTypes.EMAIL };
}



export const validatePassword = (password: string): IResponse => {
    const isString = typeof(password) === "string";
    const isSmallerThanLimit = password.length < 100;
    const isPasswordEmpty = password.length === 0;

    if(!isString) return { status: false, message: "Senha deve ser um texto", id: WarnTypes.PASSWORD };
    if(!isSmallerThanLimit) return { status: false, message: "Senha muito longa!", id: WarnTypes.PASSWORD };
    if(isPasswordEmpty) return { status: false, message: "Senha não pode estar vazia!", id: WarnTypes.PASSWORD };

    return { status: true, message: "", id: WarnTypes.PASSWORD };
}


export const validateName = (name: string): IResponse => {
    const isString = typeof(name) === "string";
    const isSmallerThanLimit = name.length < 64;
    const isNameEmpty = name.length === 0;
    const isValidName = nameRegex.test(name);

    if(!isString) return { status: false, message: "Nome deve ser um texto", id: WarnTypes.NAME };
    if(!isSmallerThanLimit) return { status: false, message: "Nome muito longo!", id: WarnTypes.NAME };
    if(isNameEmpty) return { status: false, message: "Nome não pode estar vazio!", id: WarnTypes.NAME };
    if(!isValidName) return { status: false, message: "Nome inválido!", id: WarnTypes.NAME };

    return { status: true, message: "", id: WarnTypes.NAME };
}

export const arePasswordsEqual = (pass1: string, pass2: string): IResponse => {
    return { status: pass1 === pass2, message: "As senhas devem coincidir!", id: WarnTypes.PASSWORD };
}


export const formatNumberAsCurrency = (amount: number): string => {
    return amount.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
}

export const formatInteger = (amount: number): string => {
    return amount.toFixed(0);
}

export const checkIfStringsAreSimilar = (str1: string, str2: string): boolean => {
    return str1.toLowerCase().includes(str2.toLowerCase());
}