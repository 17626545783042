import Axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import { API_URL } from '../../config';
import storage from '../../utils/storage';

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    const token = storage.getToken();
    
    if(!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    config.headers.Accept = 'application/json';
    
    return config;
}

export const axios = Axios.create({ baseURL: API_URL });

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use( (response) => response.data, (error) => Promise.reject(error) );