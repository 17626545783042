import { useState } from "react";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

interface IInput {
    id: string;
    type?: string;
    label: string;
    className?: string;
    title?: string;
    [key: string]: any;
}

export default function Input({
    id,
    type = 'text',
    label,
    className = '',
    title = '',
    ...rest
}: IInput) {
    const [inputType, setInputType] = useState(type);

    const toggleInputType = () => {
        setInputType(inputType === 'text' ? 'password' : 'text');
    };

    return (
        <div className={'form-input ' + className}>
            <input id={id} type={inputType} placeholder='' title={title} autoComplete='one-time-code' {...rest}/>
            <label htmlFor={id}>{label}</label>
            {type === 'password' && (
                <button type="button" onClick={toggleInputType}>
                    <span role="img" aria-label="eye">
                        {inputType === "password" ? <BsEyeFill size={20} color="#4a4949"/> : <BsFillEyeSlashFill size={20} color="#4a4949"/>}
                    </span>
                </button>
            )}
        </div>
    )
}